@import 'src/v2/scss/core';

.fixed-actions {
    @include flex-start-center;
    flex-direction: column;
    max-width: 100px;
    position: fixed;
    bottom: 100px;
    right: 25px;

    button {
        &.scroll-top {
            color: $blue;
            font-size: $s52;
            width: 64px;
            height: 64px;
            background: $blue;
            color: #fff;
            border-radius: 10px;
            @include flex-center;
            border: 2px solid #fff;
            margin-bottom: 80px;
        }
    }

    .action-chat {
        background: $orange;
        padding: 16px;
        border-radius: 10px;
        @include flex-center;
    }
}

@include layout-bp('lt-xl') {
    .fixed-actions {
        bottom: 75px;

        button {
            &.scroll-top {
                width: 48px;
                height: 48px;
                font-size: $s28;
                border-radius: 8px;
                margin-bottom: 40px;
            }
        }

        .action-chat {
            border-radius: 8px;
            padding: 12px;

            img {
                width: 36px;
                height: 36px;
            }
        }
    }
}

@include layout-bp('xs') {
    .fixed-actions {
        bottom: 50px;
    }
}