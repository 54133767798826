@import 'src/v2/scss/core';

@include layout-bp('lt-lg') {
    .header {
        .mobile {
            @include flex-end-center;

            &-navigation {
                position: absolute;
                top: 0;
                left: 100%;
                width: 100%;
                height: 100vh;
                overflow-y: auto;
                overflow-x: hidden;
                background: $m-nav-bg;
                color: #fff;
                font-size: 25px;
                padding: 16px 18px;
                @include flex-start-start;
                flex-direction: column;
                transition: left 0.5s ease-in-out;

                &.active {
                    left: 0;
                }

                .separator-border {
                    width: 100%;
                    margin: 20px 0;
                    border-bottom: 1px solid #fff;

                    &.no-mg-top {
                        margin-top: 10px;
                    }
                }

                .menu {
                    padding: 0 6px;
                    color: white;
                    text-decoration: none;

                    span {
                        border-bottom: 3px solid rgba(0, 0, 0, 0);
                    }

                    svg {
                        pointer-events: none;
                    }

                    &.active {
                        span {
                            border-bottom: 3px solid $orange;
                        }
                    }
                }

                .dropdown {
                    overflow: hidden;

                    &-body {
                        @include flex-start-stretch;
                        flex-direction: column;
                        max-height: 0;
                        transition: max-height 0.35s ease;

                        &:hover,
                        &:active,
                        &.active {
                            max-height: 500px;
                        }

                        a,
                        button {
                            padding-left: 30px;
                            margin: 10px 0;
                            color: white;
                            text-decoration: none;
                            text-align: left;

                            &.active {
                                span {
                                    border-bottom: 3px solid $orange;
                                }
                            }

                            span {
                                border-bottom: 3px solid rgba(0, 0, 0, 0);
                            }
                        }
                    }
                }

                .btnFlag {
                    width: 42px;
                }

                .btnLogin {
                    width: 170px;
                    height: 40px;
                    min-height: 40px;
                    margin: 0 auto;
                    border-radius: 12px;
                }
            }
        }
    }
}
