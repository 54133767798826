@mixin flex-between-stretch {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

@mixin flex-start-stretch {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

@mixin flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@mixin flex-start-end {
    display: flex;
    justify-content: flex-start;
    align-items: end;
}

@mixin flex-start-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-center-stretch {
    display: flex;
    justify-content: stretch;
    align-items: center;
}

@mixin flex-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@mixin flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-end-stretch {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
}

@mixin flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
