/* body {
    page-break-inside: avoid;
    font-size: 12pt;
    margin-left: auto;
    margin-right: auto;
    width: calc(210mm + 30px);
    min-height: 297mm;
    max-height: 297mm;
    padding-top: 30px;
} */

@font-face {
    font-family: "Calist";
    font-weight: 500;
    src: url("../../assets/fonts/CalistoMT.ttf") format('truetype');
}

@font-face {
    font-family: "KhmerMEF1";
    src: url("../../assets/fonts/KHMERMEF1.ttf") format('truetype');
}

@font-face {
    font-family: "KhmerMEF2";
    src: url("../../assets/fonts/KHMERMEF2.ttf") format('truetype');
}

.invoice{
    &-body{
        font-size: 12pt !important;    
        color: #212529;
    
        *{
            line-height: 1.5;
        }
    
        p{
            font-size: 12pt;
            margin-bottom: 1em;        
        }
    
        h6 {
            font-size: 18px;
            margin-bottom: .5em;
        }
    
        main{
            padding-top: 0;
        }
    
        .title{
            font-size: 24px;
        }
        
        .font-normal {
            font-family: "Calist", "KhmerMEF1";
        }
        
        .font-bold {
            font-family: 'Calist', 'KhmerMEF2';
        }
        
        .font-en {
            font-family: "Calist";
        }
        
        #invoice {
            margin-left: auto;
            margin-right: auto;
        }
        
        .invoice .table td,
        .invoice .table th {
            vertical-align: middle;
            border-color: #000 !important;
        }
        
        .invoice .table th {
            font-weight: normal;
            border-bottom: 1px solid #000 !important;
        }
        
        .invoice .table tfoot tr:first-child td {
            border-top: none !important;
        }
        
        .qr-block .wrap {
            float: right;
        }
        
        .qr-block:before {
            clear: both;
        }
    }

    &-footer {
        border-top: 2px solid #b4b766;
        margin: 127px 15px 0;
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
    }
    
    @media print {
        body {
            height: 100%;
            width: 210mm;
            border: none;
            padding-top: 0;
        }
    
    
        body {
            page-break-inside: avoid;
            font-size: 12pt;
            margin-left: auto;
            margin-right: auto;
            width: calc(210mm + 30px);
            min-height: 290mm;
            padding-top: 5px;
        }
    
        #invoice {
            margin-left: auto;
            margin-right: auto;
        }
    
        .table-bordered {
            border-width: 0;
        }
    
        .table thead th {
            vertical-align: middle;
            white-space: nowrap;
            text-align: center;
            font-weight: 500;
            border-bottom-color: #000 !important;
            border: 1px solid #000 !important;
        }
    
        .table tbody td {
            vertical-align: middle;
            padding: 1.5rem .3rem;
            border: 1px solid #000 !important;
        }
    
        .table tfoot th {
            font-weight: normal;
            border: 1px solid #dee2e6 !important;
        }
    
        .table-bordered th,
        .table-bordered td {
            border-bottom-color: #000 !important;
            border-color: #000 !important;
            border-width: .5px !important;
            border-collapse: separate;
        }
    
        td {
            border: 1px solid #000;
        }
    
        .invoice-footer {
            border-top: 1px solid #b4b766;
            margin: 0 15px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    
        .qr-block .wrap {
            padding-left: 50px;
        }
    
        .invoice-footer {
            margin: 0;
        }
    
        @page {
            size: A4 portrait;
        }
    }
    
    @page {
        size: A4 portrait;
    }
}