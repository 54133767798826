@import 'src/v2/scss/core';

.search {
    &-container {
        @include flex-start-stretch;

        &.isHeader {
            margin-left: 60px;
            width: 100%;

            .input {
                height: 45px;
                padding: 10px 16px;

                .prefix {
                    &.icon-search {
                        font-size: 24px;
                    }
                }

                input {
                    font-size: $s20;
                }

                .suffix {
                    padding-left: 12px;
                    font-size: $s24;
                }

                .domain {
                    &-dropdown {
                        top: 35px;
                        font-size: $s24;
                    }
                }
            }

            button.btnSubmit {
                height: 45px;
                width: 150px;
                margin-left: 16px;
                font-size: $s20;
                font-weight: $semibold;
                padding: 8px;
            }
        }

        .input {
            height: 70px;
            border-radius: 15px;
            box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.16);
            flex: 1 1 auto;
            padding: 16px 26px;
            position: relative;
            @include flex-start-center;
            background: #fff;
            font-size: $s32;

            &-container {
                flex: 1 1 auto;
            }

            .prefix {
                &.icon-search {
                    margin-right: 16px;
                }
            }

            input {
                height: 100%;
                width: 1%;
                flex: 1 1 auto;
                border: none;
                background: none;
                outline: none;
                font-weight: $medium;
                padding-right: 16px;
            }

            .suffix {
                border-left: 1px solid $border2;
                padding-left: 18px;
                cursor: pointer;

                .fa-chevron-down {
                    margin-left: 0;
                    font-size: $s24;
                }
            }

            .domain {
                &-dropdown {
                    display: none;
                    position: absolute;
                    top: 55px;
                    right: 0;
                    box-shadow: 0 10px 6px rgba(0, 0, 0, 0.16);
                    background: #fff;
                    border-radius: 0 0 20px 20px;
                    padding: 8px 18px;
                    z-index: 9;

                    &.active {
                        display: block;
                    }

                    span {
                        padding: 10px 5px 10px 5px;
                        border-bottom: 2px solid $border;
                        display: block;
                        cursor: pointer;

                        &:last-child {
                            border: none;
                        }
                    }
                }
            }
        }

        .example {
            font-size: $s24;
            margin-top: 20px;
            color: #fff;
            display: flex;
        }

        button.btnSubmit {
            background: $orange;
            color: #fff;
            padding: 16px 40px;
            border-radius: 15px;
            font-size: $s32;
            margin-left: 40px;
            font-weight: $semibold;
            height: 70px;

            &:hover,
            &:active,
            &.active {
                background: $blue;
            }
        }
    }
}

@include layout-bp('lt-xl') {
    .search {
        &-container {
            width: 100%;

            &.isHeader {
                margin-left: 0;

                .input {
                    height: 38px;
                    padding: 0 10px;
                    font-size: $s15;

                    &-container {
                        flex: 1 1 auto;
                    }

                    .prefix {
                        &.icon-search {
                            font-size: inherit;
                        }
                    }

                    input {
                        padding-right: 12px;
                        font-size: inherit;
                    }

                    .suffix {
                        padding-left: 12px;
                        font-size: inherit;
                    }

                    .domain {
                        &-dropdown {
                            font-size: inherit;
                            padding: 0 16px;
                            border-radius: 0 0 10px 10px;

                            span {
                                padding: 8px;
                            }
                        }
                    }
                }

                button {
                    &.btnSubmit {
                        width: 80px;
                        font-size: $s15;
                        height: 38px;
                        padding: 0;
                        border-radius: 10px;
                        margin-left: 14px;
                        padding: 10px 15px;
                    }
                }
            }

            .input {
                font-size: $s20;
                padding: 0 16px;
                height: 50px;
                border-radius: 10px;

                input {
                    flex: 1 1 auto;
                }

                .suffix {
                    .fa-chevron-down {
                        margin-left: 0;
                        font-size: $s15;
                    }
                }

                .domain {
                    &-dropdown {
                        top: 35px;
                        padding: 0 16px;

                        span {
                            padding: 8px;
                        }
                    }
                }
            }

            .example {
                font-size: $s15;
                margin-top: 15px;
            }

            button.btnSubmit {
                font-size: $s20;
                height: 50px;
                padding: 0 18px;
                border-radius: 10px;
                margin-left: 26px;

                &:hover,
                &:active,
                &.active {
                    background: $blue;
                }
            }
        }
    }
}

@include layout-bp('xs') {
    .search {
        &-container {
            flex-direction: column;
            @include flex-center;

            .input {
                input {
                    max-width: 204px;
                }
            }

            .example {
                font-size: $s12;
                margin-top: 8px;
            }

            button {
                &.btnSubmit {
                    margin: 24px 0 0;
                    max-width: 140px;
                    padding: 0 24px;
                }
            }
        }
    }
}
