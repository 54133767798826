@import 'src/v2/scss/core';

.page {
    &-title-v2 {
        line-height: 76px;
        color: $blue;
        font-weight: $bold;
        text-align: center;
        margin: 60px 0;
    }
}

@include layout-bp('lt-xl') {
    .page {
        &-title-v2 {
            line-height: normal;
            margin: 30px 0;
        }
    }
}
