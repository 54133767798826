@import 'src/v2/scss/core';

@include layout-bp('lt-md') {
    .container {
        max-width: 100%;

        &-1740,
        &-1250,
        &-1200 {
            max-width: 600px;
            padding: 0 16px;
            margin: 0 auto;
        }
    }
}
