@import 'src/v2/scss/core';

@include layout-bp('lt-xl') {
    .container {
        max-width: 1140px;

        &-1740,
        &-1250,
        &-1200 {
            max-width: 960px;
            padding: 0 16px;
            margin: 0 auto;
        }
    }

    h1 {
        font-size: $s25;
    }

    h2 {
        font-size: $s20;
    }

    p {
        font-size: $s15;
    }
}
