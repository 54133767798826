@import 'src/v2/scss/core';

@include layout-bp('xs') {
    .container {
        max-width: 100%;

        &-1740,
        &-1200,
        &-1250 {
            max-width: 372px;
            padding: 0 16px !important;
        }

        &-1200-max{
            max-width: 100%;
        }
    }

    p {
        font-size: $s15 !important;
    }

    .flex-row {
        margin: 0;

        .flex-column {
            margin: 0;
        }
    }
}
