@import 'src/v2/scss/core';

.header {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    background: $dark-blue-1;

    .mobile {
        display: none !important;
    }

    .desktop {
        display: flex;
        flex: 1 1 auto;
    }

    h1 {
        font-size: 0;
    }

    .fa-chevron-down {
        margin-left: 12px;
        transition: rotate 0.5s;
    }

    .flex-row {
        .flex-column {
            @include flex-end-center;

            &:last-child {
                flex: 1 1 auto;
            }
        }
    }

    &-container {
        @include flex-between-stretch;

        nav {
            @include flex-center;
            height: 100%;

            .menu {
                color: #fff;
                position: relative;
                display: flex;
                padding: 8px 16px;
                margin-left: 9px;
                text-decoration: none;

                &:first-child {
                    margin-left: 0;
                }

                &:hover,
                &:active {
                    .fa-chevron-down {
                        rotate: 180deg;
                    }

                    .dropdown {
                        display: block;
                    }
                }

                &.btnLogin {
                    border-radius: 12px;
                    background: #fff !important;
                    color: $dark-blue-1;
                    font-weight: $semibold;

                    &:hover,
                    &:active,
                    &.active {
                        background: $orange !important;
                        color: #fff;
                    }
                }

                span {
                    border-bottom: 3px solid rgba(0, 0, 0, 0);
                }

                .curp {
                    cursor: pointer;
                }

                .dropdown {
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 16px;
                    width: 312px;
                    background: #fff;
                    border-radius: 8px;
                    z-index: 3;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

                    &.right {
                        right: 0;
                        left: unset;
                    }

                    &-header {
                        background: $orange;
                        height: 7px;
                        border-radius: 8px 8px 0 0;
                        padding: 0;
                    }

                    &-body {
                        display: flex;
                        justify-content: stretch;
                        align-items: flex-start;
                        flex-direction: column;
                        padding: 12px 20px 10px;

                        a,
                        button {
                            width: 100%;
                            // height: 52px;
                            color: $dark-blue-1;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-bottom: 2px solid $border;
                            text-decoration: none;
                            padding: 5px 0 5px 0;

                            &:last-child {
                                border-bottom: 0;
                            }

                            &:hover,
                            &:active,
                            &.active {
                                color: $orange;
                            }
                        }
                    }

                    &-footer {
                        height: 7px;
                        border-radius: 0 0 8px 8px;
                    }
                }
            }

            a.menu {
                &:hover,
                &:active,
                &.active {
                    span {
                        border-bottom: 3px solid $orange;
                    }
                }
            }

            .btnFlag {
                margin-left: 25px;

                svg {
                    width: 42px;
                }
            }
        }
    }
}

@include layout-bp('lt-xl') {
    .header {
        &-container {
            nav {
                .menu {
                    margin-left: 0;
                    .dropdown {
                        width: 200px;

                        &-body {
                            padding: 8px 16px;

                            a {
                                height: auto;
                                padding: 8px 0;
                            }
                        }
                    }
                }
            }
        }

        .container {
            border-radius: 0 0 15px 15px;
            padding: 0 16px;
        }
    }

    .btnFlag {
        svg {
            width: 36px !important;
        }
    }

    .btnLogin {
        border-radius: 10px !important;
        height: 38px;
        @include flex-center;
    }
}

@include layout-bp('lt-lg') {
    header {
        &.header {
            height: 52px;

            .container {
                border-radius: 0 0 15px 15px;
                // padding: 0 16px;
                max-width: 960px;
            }

            &-container {
                @include flex-between-stretch;
                max-width: 960px;
            }

            .desktop {
                display: none !important;
            }

            .mobile {
                display: flex !important;
            }

            h1 {
                picture,
                img {
                    max-width: 158px;
                }
            }

            &-container {
                nav {
                    display: none;

                    .btnFlag {
                        svg {
                            width: 36px;
                        }
                    }
                }
            }
        }
    }
}
