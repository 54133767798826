// COLORS
$blue: #014282;
$light-blue: #017dc5;
$dark-blue: #143f77;
$orange: #ff7c00;
$text: #4d4d4d;
$border: #9d9d9d;
$border2: #707070;
$white: #fff;
$white-bg: #fafafa;
$ocean-blue: #23b0e6;
$text-alpha15: rgba(77, 77, 77, 0.15);
$light-blue-alpha05: rgba(1, 125, 197, 0.05);
$shadow: rgba(0, 0, 0, 0.16);
$m-nav-bg: #084c90;

$gray-1: #333;
$gray-2: #4f4f4f;
$gray-3: #828282;
$gray-4: #bdbdbd;
$gray-5: #e0e0e0;
$gray-6: #f2f2f2;
$red-1: #eb5757;
$orange-1: #f2994a;
$yellow-1: #f2c94c;
$green-1: #219653;
$green-2: #27ae60;
$green-3: #6fcf97;
$blue-1: #2f80ed;
$blue-2: #2d9cdb;
$blue-3: #56ccf2;
$purple-1: #9b51e0;
$purple-2: #bb6bd9;
$light-blue-1: #f2f8fc;
$dark-blue-1: #004282;
$dark-blue-2: #012d58;
$light-gray-1: #c4c4c4;

// FONTS
$kantumruy: 'Kantumruy Pro', sans-serif;
$poppins: 'Poppins', sans-serif;

// FONT WEIGHT
$bold: 700;
$semibold: 600;
$medium: 500;

// FONT SIZES
$s64: 64px;
$s55: 55px;
$s52: 52px;
$s48: 48px;
$s46: 46px;
$s40: 40px;
$s35: 35px;
$s32: 32px;
$s30: 30px;
$s28: 28px;
$s25: 25px;
$s24: 24px;
$s20: 20px;
$s18: 18px;
$s15: 15px;
$s14: 14px;
$s12: 12px;

$p: 25px;
$small: 20px;
$mini: 18px;

$pp52: 52px;
$pp30: 30px;

// SVG SIZES
$header-chevron: 18px;
$footer-info: 28px;
$footer-social: 44px;
$home-search: 36px;
