@import 'src/v2/scss/core';

.footer {
    color: #fff;
    font-size: $s20;
    font-weight: $medium;
    background: $blue;
    padding-top: 60px;

    .container-1200 {
        position: relative;
    }

    a {
        text-decoration: none;
        color: white;
    }

    .logos {
        margin-bottom: 24px;

        a {
            margin-right: 18px;
            &:last-child {
                margin-right: 0;
            }

            svg{
                width: 90px;
            }

            img {
                width: 100px;
            }
        }
    }

    &-info {
        svg {
            font-size: 24px;
        }
    }

    &-socials {
        h5 {
            font-size: $s20;
            font-weight: $medium;
            line-height: normal;
            margin-bottom: 15px;
        }

        svg {
            font-size: 36px;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-bg {
        background: $dark-blue;
    }

    &-center {
        @include flex-between-stretch;
        padding-bottom: 34px;
    }

    &-info {
        @include flex-start-stretch;
        flex-direction: column;

        a {
            @include flex-start-center;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                margin-right: 12px;
            }
        }
    }

    &-links {
        @include flex-start-stretch;
        flex-direction: column;

        a {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-socials {
        input[type='email'] {
            height: 42px;
            border-radius: 24px;
            border: none;
            background: #fff;
            width: 286px;
            margin-bottom: 16px;
            padding: 10px 20px;
            outline: none;
        }

        .socials {
            @include flex-start-stretch;

            a {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }

                &:hover,
                &:active,
                &.active {
                    color: $orange;
                }
            }
        }
    }

    &-bottom {
        color: #fff;
        @include flex-start-center;
        background: $dark-blue-2;
        padding: 16px 0 16px;

        .flex-row {
            @include flex-between-stretch;

            align-items: center;
        }

        p {
            font-size: $s20;
            // margin-bottom: 8px;

            span {
                line-height: 1.5;

                &:nth-child(2) {
                    padding-left: 15px;
                    margin-left: 15px;
                    border-left: 1px solid $border;
                }
            }
        }

        a {
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        span.accept {
            display: block;
            margin-right: 12px;
            line-height: 1.5;
        }

        img {
            max-width: 280px;
        }
    }
}

@include layout-bp('lt-xl') {
    .footer {
        font-size: $s15;
        padding-top: 40px;

        &-socials {
            h5 {
                font-size: inherit;
            }

            .socials {
                a {
                    margin-right: 16px;
                }
            }

            svg {
                font-size: $s24;
            }
        }

        &-bottom {
            .flex-row {
                margin-right: 0;
            }

            padding: 14px 0;

            p {
                font-size: inherit;
            }

            picture,
            img {
                max-width: 180px;
            }
        }

        .container {
            border-radius: 15px 15px 0 0;
        }
    }
}

@include layout-bp('lt-md') {
    .footer {
        &-center {
            flex-wrap: wrap;
        }

        &-socials {
            margin-top: 24px !important;
        }

        &-bottom {
            &-container {
                flex-wrap: wrap;
            }

            &-payments {
                margin-left: 15px !important;
                margin-right: 15px !important;
                margin-top: 24px !important;
            }
        }
    }
}

@include layout-bp('xs') {
    .footer {
        .container {
            border-radius: 15px 15px 0 0;
        }

        .logos {
            @include flex-center;

            a {
                margin-right: 36px;

                &:last-child {
                    margin-right: 0;
                }
            }

            picture,
            img {
                width: 102px;
                height: 102px;
            }
        }

        &-center {
            @include flex-start-stretch;
            flex-direction: column;
        }

        &-info {
            order: 2;
            margin: 0 15px 24px !important;
        }

        &-links {
            order: 3;

            a {
                margin-bottom: 28px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-socials {
            order: 1;
            margin: 0 15px 24px !important;

            h5 {
                font-size: $s15;
            }

            .socials {
                max-width: 276px;
            }
        }

        &-bottom {
            text-align: center;

            .flex-row {
                @include flex-start-stretch;
                flex-direction: column;
                align-items: center;
            }

            p {
                font-size: $s15;

                span {
                    display: block;
                    border-left: none !important;
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }

            .links {
                margin: 24px 0;
            }
        }
    }
}
